import {useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import {KTCard, KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import {dateTimeISOFormatter} from '../../../../helpers/datetime-formatter'
import {FormAction} from '../../../../helpers/form-action-interfaces'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import {useUserStore, useUserFormStore} from '../states/user.state'
import {UserData} from '../../../auth'
import UserModalComponent from '../components/user-modal.component'
import clsx from 'clsx'
import {generateImageUrl} from '../../../../helpers/generate-image-url'

const UserListComponent = () => {
  const {getUsers, userItems, userLoading, userModel, onDelete} = useUserStore()
  const {setField, field, generateForms, submitDone, formLoading, reset} = useUserFormStore()

  useEffect(() => {
    getUsers({
      page: 0,
      size: 10,
    })
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getUsers({page: 0, size: 10})
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getUsers({
      page: selectedPage,
      size: 10,
    })
  }

  const openEditModal = (item: UserData) => {
    setField(item)
    generateForms({
      title: 'User',
      subtitle: `Update User ${item.full_name}`,
      action: FormAction.UPDATE,
      data: item,
      id: item.id,
    })
  }

  return (
    <>
      {/* <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}></div>
      </div> */}
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Users</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>User List</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Create New User',
                  subtitle: 'Create New User',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create New User
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {userLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>User Name</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                  <th>Created At</th>
                  <th className='text-end min-w-100px'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {userItems?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <a href='#'>
                              {item.avatar !== '' ? (
                                <div className='symbol-label'>
                                  <img
                                    src={generateImageUrl(item.avatar)}
                                    alt={item.full_name}
                                    className='w-100'
                                  />
                                </div>
                              ) : (
                                <div
                                  className={clsx(
                                    'symbol-label fs-3',
                                    `bg-light-success`,
                                    `text-success`
                                  )}
                                >
                                  {item.full_name[0]}
                                </div>
                              )}
                            </a>
                          </div>
                          <div className='d-flex flex-column'>
                            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                              {item.full_name}
                            </a>
                            <span>{item.email}</span>
                          </div>
                        </div>
                      </td>
                      <td>{item.phone_number}</td>
                      <td>{item.role?.name}</td>
                      <td>{dateTimeISOFormatter(item.created_at)}</td>
                      <td className='text-end min-w-100px'>
                        <a
                          href='#'
                          className='btn btn-light btn-active-light-primary btn-sm'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          Actions
                          <KTIcon iconName='down' className='fs-5 m-0' />
                        </a>
                        {/* begin::Menu */}
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                          data-kt-menu='true'
                        >
                          {/* begin::Menu item */}
                          <div className='menu-item px-3'>
                            <a className='menu-link px-3' onClick={() => openEditModal(item)}>
                              Edit
                            </a>
                          </div>
                          {/* end::Menu item */}

                          {/* begin::Menu item */}
                          <div className='menu-item px-3'>
                            <a
                              className='menu-link px-3'
                              data-kt-users-table-filter='delete_row'
                              onClick={() => {
                                onDelete(item.id, {
                                  page: 0,
                                  size: 10,
                                })
                              }}
                            >
                              Delete
                            </a>
                          </div>
                          {/* end::Menu item */}
                        </div>
                        {/* end::Menu */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={userModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <UserModalComponent />}
    </>
  )
}

const UserListWrapper = () => {
  return (
    <>
      <UserListComponent />
    </>
  )
}

export default UserListWrapper
