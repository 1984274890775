import {useEffect, useState} from 'react'
import {useMasterStockStore} from '../states/master-stock.state'
import {UsersListLoading} from '../../user-management/users-list/components/loading/UsersListLoading'
import ReactPaginate from 'react-paginate'
import {KTCard, KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import moment from 'moment'
import {useProductBundleFormStore, useProductBundleStore} from '../states/product-bundle.state'
import ProductBundleModalComponent from '../components/product-bundle-modal.component'
import {FormAction} from '../../../../helpers/form-action-interfaces'
import ProductBundleDetailModalComponent from '../components/product-bundle-detail.modal.component'
import {ProductBundleData} from '../interfaces/product-bundle-result.interface'

const ProductBundleComponent = () => {
  const {getProductBundles, productBundles, productBundleLoading, productBundleModel, onDelete} =
    useProductBundleStore()
  const {setField, field, generateForms, submitDone, formLoading, reset, getProductBundleDetail} =
    useProductBundleFormStore()

  useEffect(() => {
    getProductBundles({
      page: 0,
      size: 25,
    })
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getProductBundles({
        page: 0,
        size: 25,
      })
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getProductBundles({
      page: selectedPage,
      size: 25,
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [detailId, setDetailId] = useState<string>('')

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openEditModal = async (item: ProductBundleData) => {
    setField(item)
    await getProductBundleDetail(item.id)
    generateForms({
      title: 'Product Bundle',
      subtitle: `Update Product Bundle ${item?.bundle_name}`,
      action: FormAction.UPDATE,
      data: item,
      id: item.id.toString(),
    })
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Warehouse</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Product Bundle</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Add Product Bundle',
                  subtitle: 'Add Product Bundle',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Product Bundle
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {productBundleLoading && <UsersListLoading />}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Nama Bundle</th>
                  <th>Tanggal Dibuat</th>
                  <th>Detail</th>
                  <th className='text-end min-w-100px'>Action</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {productBundles?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.bundle_name}</td>
                      <td>{moment(item?.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>
                        <button
                          className='btn btn-success'
                          onClick={(e) => {
                            e.preventDefault()
                            openModal()
                            setDetailId(item?.id)
                          }}
                        >
                          Detail
                        </button>
                      </td>
                      <td className='text-end min-w-100px'>
                        <a
                          href='#'
                          className='btn btn-light btn-active-light-primary btn-sm'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          Actions
                          <KTIcon iconName='down' className='fs-5 m-0' />
                        </a>
                        {/* begin::Menu */}
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                          data-kt-menu='true'
                        >
                          {/* begin::Menu item */}
                          <div className='menu-item px-3'>
                            <a
                              className='menu-link px-3'
                              onClick={(e) => {
                                e.preventDefault()
                                openEditModal(item)
                              }}
                            >
                              Edit
                            </a>
                          </div>
                          {/* end::Menu item */}

                          {/* begin::Menu item */}
                          <div className='menu-item px-3'>
                            <a
                              className='menu-link px-3'
                              data-kt-users-table-filter='delete_row'
                              onClick={(e) => {
                                e.preventDefault()
                                onDelete(item?.id, {
                                  page: 0,
                                  size: 25,
                                })
                              }}
                            >
                              Delete
                            </a>
                          </div>
                          {/* end::Menu item */}
                        </div>
                        {/* end::Menu */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={productBundleModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <ProductBundleModalComponent />}
      <ProductBundleDetailModalComponent isOpen={isModalOpen} onClose={closeModal} id={detailId} />
    </>
  )
}

const ProductBundleWrapper = () => {
  return (
    <>
      <ProductBundleComponent />
    </>
  )
}

export default ProductBundleWrapper
